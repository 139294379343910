<template>
	<div>
		<transition name="fade" mode="out-in">
			<router-view 
				:props_search="props_search" />
		</transition>
	</div>
</template>

<script>
	export default {

		name: 'EmptyParentComponent',
		props: {
			props_search:		{
				type: 		String,
				default:	''
			}
		},
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>

</style>