var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content mb-5"},[_c('Loading'),_c('Navbar',{attrs:{"is_home":_vm.is_home},on:{"search":_vm.UpdateSearch,"support":_vm.ClearArticles,"update":_vm.UpdateArticles}}),(_vm.is_home)?_c('Search',{ref:"search",on:{"search":_vm.UpdateSearch,"update":_vm.UpdateArticles}}):_vm._e(),(!_vm.is_home)?_c('div',{staticClass:"col-md-6 offset-md-3 mb-5"},[_c('button',{staticClass:"px-5 btn-previous btn btn-light",on:{"click":_vm.Previous}},[_c('i',{staticClass:"fal fa-chevron-left px-2"})])]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.search.length <= 2)?_c('router-view',{attrs:{"props_search":_vm.search}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.search.length > 2)?_c('div',{key:"search",staticClass:"my-5 col-12 col-md-6 mx-auto sections-container"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Resultado da pesquisa - "+_vm._s(_vm.search))]),(_vm.articles.length > 0)?_c('ul',{staticClass:"list-container"},_vm._l((_vm.articles),function(article){return _c('li',{key:article.id,on:{"click":function($event){return _vm.ClearArticles()}}},[(article.category && article.section)?_c('router-link',{attrs:{"to":{ name: 'Article', params: {
								category_slug: article.category.slug,
								section_slug: article.section.slug,
								article_slug: article.slug
							}}}},[_vm._v(" "+_vm._s(article.titulo)+" ")]):_vm._e()],1)}),0):_vm._e(),(_vm.quantity != null)?_c('small',[_vm._v("Quantidade de artigos encontrados: "),_c('b',[_vm._v(_vm._s(_vm.quantity))])]):_vm._e()]):(!_vm.search.length < 3 && _vm.is_home)?_c('div',{key:"fa",staticClass:"my-5 col-12 col-md-6 mx-auto fa-container"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Perguntas Frequentes")]),_c('ul',{staticClass:"list-container"},_vm._l((_vm.fa_sections),function(section){return _c('li',{key:section.id},[_c('router-link',{staticClass:"col-12",attrs:{"to":{ name: 'Section', params: {
								category_slug: 'perguntas-frequentes',
								section_slug: section.slug
							}}}},[_vm._v(" "+_vm._s(section.descricao)+" ")])],1)}),0)]):_vm._e()])],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"col-12 footer-container"},[_c('div',{staticClass:"footer-icons mt-2"},[_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"mx-2 fab fa-linkedin-in"})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"mx-2 fab fa-facebook"})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"mx-2 fab fa-instagram"})]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"mx-2 fab fa-youtube"})])]),_c('p',{staticClass:"footer-text"},[_vm._v("© Copyright 2021 Metodologia Instant. Todos os direitos reservados. ")])])}]

export { render, staticRenderFns }