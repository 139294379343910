<template>
  <div class="col-12">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
    export default {
        
        name: 'Index',

        data () {
            return {
              
            }
        }
    }
</script>

<style lan="css" scoped>
</style>
