<template>
    <div>
        <div class="search-container my-5">
            <h5>Bem vindo ao centro de ajuda do Instant Medical</h5>
            <h1 class="col-12 text-center text-uppercase font-24">Como podemos ajudar?</h1>
            <div class="col-12 col-md-7 col-lg-5">
                <input
                    v-debounce:500="SearchDeep"
                    type="text"
                    class="col-12 form-control search"
                    :class="{ 'is-invalid': $v.search.$error }"
                    v-model="$v.search.$model"
                    placeholder="Digite palavras-chave para encotrar sua resposta"
                    >
                <div class='invalid-feedback text-center'>
                    Deve ter no mínimo 3 caractéres.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import { minLength } from 'vuelidate/lib/validators'

import Swal from 'sweetalert2'

const _ = require('lodash')
export default {
    name: 'Search',
    props: {
        is_home:    {
            type:       Boolean,
            default:    true,
        }
    },
    data () {
        return {
            search: ""
        }
    },
    validations: {
        search: {
            minLength: minLength(3)
        }
    },
    watch: {
        search: {
            handler(val)
            {
                this.$emit('search', this.$v.search.$model)
            }
        },
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async SearchDeep()
        {
            const vm = this
            if(vm.search.length == 0) return

            vm.$v.search.$touch()

            if(!vm.$v.search.$invalid)
            {
                vm.StartLoading()
    
                return await window.api.call('post', '/search-articles', {
                    busca: vm.search
                })
                    .then(({data}) => {
                        if(data.response)
                        {
                            vm.$emit('update', {
                                articles: data.articles,
                                quantity: data.quantidade
                            })
                            return
                        }
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            }
        }
    }
}
</script>

<style>
</style>