<template>
	<div>
		<header 
			class=" ps-t-0 ps-l-0 ps-r-0"
			:class="{
        		'px-4 py-3': 	!carousel,
        		'w-100 p-4': 	carousel
        		}"
			>
   			<div class="row justify-content-center align-items-center"
			   	:class="is_home ?  'justify-content-md-around' : ' justify-content-md-center'">
        		<div class="col-auto mb-2 mr-4">
					<router-link
						:to="{ name: 'Home' }">
						<img :src="img.logo" width="200">
					</router-link>
				</div>

				
				<div v-if="!is_home"
					class="col-12 p-0 col-md-3 d-flex flex-column align-items-center mr-4">
					<input
						v-debounce:500="SearchDeep"
						type="text"
						class="col-12 form-control font-18 search"
						:class="{ 'is-invalid': $v.search.$error }"
						v-model="$v.search.$model"
						placeholder="Digite palavras-chave para encotrar sua resposta"
						>
					<div class='invalid-feedback text-center search-feedback'>
						Deve ter no mínimo 3 caractéres.
					</div>
				</div>

		        <div class="col-4 col-md-auto mt-3 mt-md-0 px-md-0"
					v-if="!is_home">
					<div class="col-2 col-sm-4 col-md-auto">
						<router-link
							class="btn btn-block px-3 btn-light"
							:to="{ name: 'Home' }"
						>
							<i class="far fa-home mx-2"></i>
						</router-link>
					</div>
				</div>

		        <div class="col-4 col-md-auto mt-3 mt-md-0 px-md-0">
					<div class="col-2 col-sm-4 col-md-auto px-md-0"
							@click="$emit('support')">
						<router-link
							class="btn btn-block px-3 btn-light"
							:class="is_home ? 'd-flex justify-content-center align-items-center' : ''"
							:to="{ name: 'Contact' }"
						>
							<i class="far fa-phone-alt mx-2"></i>
							<p v-if="is_home"
								class="m-0">CONTATO</p>
						</router-link>
					</div>
				</div>
			</div>
		</header>
	</div>

</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import { minLength } from 'vuelidate/lib/validators'

	import Swal from 'sweetalert2'

	const _ = require('lodash')
	export default {

		name: 		'Navbar',
		components: 	{
		},
		props: 			{
			carousel: 		{
				type: 			Boolean,
				default: 		false
			},
			is_home:		{
				type:			Boolean,
				default:		true
			}
		},
		data () {
			return {
				img: 		{
					logo: 				require('../../../assets/img/1-2-logo-vertical-shadow.png')
				},
				search:		''
			}
		},
		validations: {
			search: {
				minLength: minLength(3)
			}
		},
		computed: {
		},
		watch: {
			search: {
				handler(val)
				{
					this.$emit('search', val)
				}
			},
			$route: {
				handler(val)
				{
					this.search = ""
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async SearchDeep()
			{
				const vm = this
				if(vm.search.length == 0) return

				vm.$v.search.$touch()

				if(!vm.$v.search.$invalid)
				{
					vm.StartLoading()
	
					return await window.api.call('post', '/search-articles', {
						busca: vm.search
					})
						.then(({data}) => {
							if(data.response)
							{
								this.$emit('update', {
									articles: data.articles,
									quantity: data.quantidade
								})
								return
							}
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}
        	}
		},
		mounted() {

		}
	}
</script>

<style lang="css" scoped>

.online-tooltip
{
	background-color: #2C2F3A;
	border: 1px solid #2C2F3A;
	border-radius: 15px;
	position: absolute;
	top: 50px;
}

.status-indicator
{
	margin: 0 15px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	align-self: center;
}

.status-indicator::before
{
	content: '';
	border: 1px solid #8e8e8e;
	background-color: #8e8e8e;
	position: absolute;
	top: 6px;
	left: 10px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.status-indicator.online::before
{
	border-color: #20c964;
	background-color: #20c964;
	box-shadow: 0px 0px 7px 2px #20c964;
}

@keyframes example {
  from {background-color: red;}
  to {background-color: yellow;}
}

.search-feedback
{
	position: absolute;
	top: 60px;
}

</style>