<template>
	<div class="container-large mt-5">
        <Categories />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

    import Categories from './../categories/Categories'

	export default {

		name: 'Index',
        components: {
            Categories
        },
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>



</style>