<template>
	<div>
		<div class="main-content mb-5">
			<Loading />
			<Navbar 
				:is_home="is_home"
				@search="UpdateSearch"
				@support="ClearArticles"
				@update="UpdateArticles" />
			<Search 
				ref="search"
				v-if="is_home"
				@search="UpdateSearch"
				@update="UpdateArticles" />

			<div class="col-md-6 offset-md-3 mb-5"
				v-if="!is_home">
				<button class="px-5 btn-previous btn btn-light"
					@click="Previous">
					<i class="fal fa-chevron-left px-2"></i>
				</button>
			</div>

			<transition name="fade" mode="out-in">
				<router-view 
					v-if="search.length <= 2"
					:props_search="search" />
			</transition>

			<transition name="fade" mode="out-in">
				<div class="my-5 col-12 col-md-6 mx-auto sections-container"
					v-if="search.length > 2"
					key="search">
					<h4 class="text-uppercase">Resultado da pesquisa - {{ search }}</h4>
					<ul class="list-container"
						v-if="articles.length > 0">
						<li v-for="article in articles"
							:key="article.id"
							@click="ClearArticles()">
							<router-link
								v-if="article.category && article.section"
								:to="{ name: 'Article', params: {
									category_slug: article.category.slug,
									section_slug: article.section.slug,
									article_slug: article.slug
								}}">
								{{ article.titulo }}
							</router-link>
						</li>
					</ul>
					<small
						v-if="quantity != null">Quantidade de artigos encontrados: <b>{{ quantity }}</b></small>
				</div>
				<div class="my-5 col-12 col-md-6 mx-auto fa-container"
					v-else-if="!search.length < 3 && is_home"
					key="fa">
					<h4 class="text-uppercase">Perguntas Frequentes</h4>
					<ul class="list-container">
						<li v-for="section in fa_sections"
							:key="section.id">
							<router-link
								class="col-12"
								:to="{ name: 'Section', params: {
									category_slug: 'perguntas-frequentes',
									section_slug: section.slug
								}}">
								{{ section.descricao }}
							</router-link>
						</li>
					</ul>
				</div>
			</transition>
		</div>

		<footer class="col-12 footer-container">
			<div class="footer-icons mt-2">
				<a href="">
					<i class="mx-2 fab fa-linkedin-in"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-facebook"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-instagram"></i>
				</a>
				<a href="">
					<i class="mx-2 fab fa-youtube"></i>
				</a>
			</div>
			<p class="footer-text">© Copyright 2021 Metodologia Instant. Todos os direitos reservados.
			</p>
		</footer>
	</div>
</template>

<script>

	import Navbar from './sections/Navbar'
	import CarouselMenu from './sections/CarouselMenu'
	import Search from './Search'
	import Loading from './Loading'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		components: 	{
			Navbar,
			CarouselMenu,
			Search,
			Loading
		},
		data () {
			return {
				carousel: 	false,
				search:		"",
				articles:	[],
				quantity:	null
			}
		},
		computed: {
            ...mapGetters('system', [
                'sections'
            ]),
            fa_sections()
            {
                return this.sections.filter(s => s.faq_category_id === 1)
            },
			menuType()
			{
				if(this.$route.meta.menuType)
				{
					return this.$route.meta.menuType
				
				} else {

					return 'default'
				}
			},
			is_home()
			{
				return this.$route.name == 'Home'
			}
		},
		watch: {
			$route(to, from)
			{
				this.carousel 	= false

				window.jQuery('.modal').modal('hide');
			},
			search: {
				handler(val)
				{
					if(val == 0)
					{
						this.articles = []
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'GetSections'
			]),
			UpdateSearch(val)
			{
				this.search = val
			},
			UpdateArticles(val)
			{
				this.articles = val.articles
				this.quantity = val.quantity
			},
			Previous()
			{
				this.$router.go(-1)
			},
			ClearArticles()
			{
				this.articles = []
				this.quantity = null
				this.search = ''
			}
		},
        beforeMount()
        {
          this.GetSections()
        }
	}
</script>

<style lang="css" scoped>

.main-content
{
    min-height: calc(100vh - 40px);
}

</style>